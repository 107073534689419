import { ReactComponent as USDTSVG } from '@/assets/svg/token/usdt.svg'
import { Button, Input, Spinner } from '@nextui-org/react'

import { ReactComponent as USDTMINSVG } from '@/assets/svg/token/usdt-min.svg'
import useHomeHooks from '@/hooks/useHomeHooks'
import { useMemo, useState } from 'react'
import BigNumber from 'bignumber.js'
import { cnTw, numFormat, numFormat2 } from '@/utils'
import toast from 'react-hot-toast'
import { useSendTransaction } from '@/lib/contract/useSendTransaction'
import getChainConfig from '@/lib/web3/getChainConfig'
import { VAULTPROXY } from '@/contract/abi'
import { encodeFunctionData, parseEther } from 'viem'
import { useTranslation } from 'react-i18next'

import { useWeb3Modal, useWeb3ModalAccount } from '@web3modal/ethers/react'
import moment from 'moment'

import Regular from './components/Regular'

export const AddTime = 30

export default function WithDrawPage() {
	const { t } = useTranslation()

	const { open } = useWeb3Modal()
	const { isConnected } = useWeb3ModalAccount()

	const { onSendTransaction } = useSendTransaction()
	const { contracts } = getChainConfig()
	const VaultProxy_ADDRESS = contracts['VaultProxy_ADDRESS']

	const { loading, mutate: HomeMutate, data } = useHomeHooks()
	const { rewards, currentWithdrawAmount, lastReceiveAt } = useHomeHooks().data

	// 需要解除质押的金额
	const [stakeNumber, setStakeNum] = useState<string>('')
	// 操作load
	const [isLoading, setIsLoading] = useState<boolean>(false)
	// 领取load
	const [isRewardLoading, setIsRewardLoading] = useState<boolean>(false)
	// 领取类型
	const [rewardType, setRewardType] = useState<'reward' | 'stake'>('reward')

	const isUnStakeUSDT = useMemo(() => {
		return currentWithdrawAmount > 0
	}, [currentWithdrawAmount])

	// 是否可以显示领取、再质押的按钮
	const isReward = useMemo(() => {
		let total = new BigNumber(rewards.team).plus(rewards.other).toNumber()
		return total > 0
	}, [rewards.other, rewards.team])

	// 赎回 质押金额
	const handleWithdrawClick = async () => {
		setIsLoading(true)
		const isTrue = stakeWithdrawIsTrue()
		if (!isTrue) {
			setIsLoading(false)
			return
		}
		try {
			let abiData = [parseEther(stakeNumber)]
			const data = encodeFunctionData({
				abi: VAULTPROXY,
				functionName: 'withdraw',
				args: abiData,
			})
			let receipt = await onSendTransaction({
				data: data,
				to: VaultProxy_ADDRESS,
				onTransactionHash: (hash: string) => {
					console.log('Transaction hash:', hash)
				},
			})
			console.log('receipt', receipt)
			toast.success(t('home.message.tip8'), { duration: 2000, id: 'RedemptionSuccess' })
			setStakeNum('')
			setIsLoading(false)
			HomeMutate()
		} catch (error: any) {
			toast.error(error?.message || error, { duration: 2000, id: 'RedemptionErr' })
			setIsLoading(false)
		}
	}

	// 解除质押前条件判断
	const stakeWithdrawIsTrue = (): boolean => {
		if (!isConnected) return false
		else if (Number(stakeNumber) > currentWithdrawAmount) {
			toast.error(t('home.message.tip9', { msg: currentWithdrawAmount }), {
				id: 'stakeWithdrawIsTrue',
				duration: 2000,
			})
			return false
		} else return true
	}

	// 领取、再质押点击
	const handleRewardClick = async (isStake = false) => {
		await setRewardType(isStake ? 'stake' : 'reward')
		setIsRewardLoading(true)
		if (!isConnected) {
			setIsRewardLoading(false)
			return
		}
		if (isStake && data.currentWithdrawAmount < 100) {
			setIsRewardLoading(false)
			toast.error(t('home.message.tip10'), { duration: 2000, id: 'stakeWithdrawIsTrue' })
			return
		}
		try {
			let abiData = [isStake]
			const data = encodeFunctionData({
				abi: VAULTPROXY,
				functionName: 'getReward',
				args: abiData,
			})
			let receipt = await onSendTransaction({
				data: data,
				to: VaultProxy_ADDRESS,
				onTransactionHash: (hash: string) => {
					console.log('Transaction hash:', hash)
				},
			})
			console.log('receipt', receipt)
			toast.success(!isStake ? t('home.message.tip0') : t('home.message.tip2'), { duration: 2000, id: 'RedemptionSuccess' })
			setStakeNum('')
			setIsRewardLoading(false)
			HomeMutate()
		} catch (error: any) {
			toast.error(error?.message || error, { duration: 2000, id: 'RedemptionErr' })
			setIsRewardLoading(false)
		}
	}

	const isClaim = useMemo(() => {
		if (lastReceiveAt === 0) return true
		return !moment.unix(lastReceiveAt).add(AddTime, 'minutes').isAfter(moment())
	}, [lastReceiveAt])

	return (
		<div className="flex w-full flex-col items-center">
			{loading && (
				<div className="absolute left-0 top-0 z-[60] flex h-full w-full items-center justify-center bg-white/5 backdrop-blur-[1px] sm:rounded-b-2xl">
					<Spinner label={t('app.loading')} labelColor="warning" color="warning"></Spinner>
				</div>
			)}
			<div className="flex items-center gap-2 pb-5">
				<USDTSVG className="h-[1.4375rem] w-[1.4375rem]" />
				<span className="tail-text-menu py-2 text-2xl font-bold">{t('home.usdt.title2')}</span>
			</div>
			<Input
				placeholder=""
				classNames={{
					inputWrapper: 'tail-input-wrapper h-10',
					input: 'text-sm text-white',
				}}
				isReadOnly={loading}
				type="number"
				value={stakeNumber}
				onValueChange={setStakeNum}
				endContent={
					<span
						onClick={() => {
							if (!isUnStakeUSDT) return
							else if (isLoading) return
							if (!numFormat2(currentWithdrawAmount, 'boollean')) return
							setStakeNum(numFormat(currentWithdrawAmount))
						}}
						className={`${!isUnStakeUSDT ? 'cus-noactive' : ''} shrink-0 cursor-pointer text-sm font-bold text-[#F7931A]`}
					>
						{t('home.usdt.max')}
					</span>
				}
			/>
			<div className="flex w-full items-center gap-0.5 py-4">
				<span className="text-sm font-normal text-[#CECFE6]">{t('home.withdraw.title')}</span>
				<div className="flex items-center gap-1">
					<span className="text-sm font-bold text-white">{numFormat(currentWithdrawAmount)}</span>
					<USDTMINSVG className="h-[0.75rem] w-[0.875rem]" />
				</div>
			</div>
			{isConnected && (
				<Button
					onClick={() => {
						if (Number(stakeNumber) > 0) handleWithdrawClick()
					}}
					isLoading={isLoading}
					isDisabled={!(Number(stakeNumber) > 0)}
					className={`${Number(stakeNumber) > 0 ? 'tail-btn-active' : 'tail-btn-no-data'} mb-5 w-1/2`}
				>
					<span>{t('home.withdraw.2')}</span>
				</Button>
			)}
			<div className="mb-4 h-[0.0625rem] w-full bg-[#454545]"></div>
			<div className="flex w-full justify-around gap-3 pb-5 pt-4">
				<div className="flex w-full flex-col-reverse items-center gap-1">
					<span className="text-sm font-normal text-[#CECFE6]">{t('home.withdraw.earnings')}</span>
					<div className="flex items-center gap-1">
						<span className="text-sm font-bold text-white">{numFormat(rewards.other)}</span>
						<USDTMINSVG className="h-[0.75rem] w-[0.875rem]" />
					</div>
				</div>
				<div className="flex w-full flex-col-reverse items-center gap-1">
					<span className="text-sm font-normal text-[#CECFE6]">{t('home.withdraw.amount')}</span>
					<div className="flex items-center gap-1">
						<span className="text-sm font-bold text-white">{numFormat(rewards.team)}</span>
						<USDTMINSVG className="h-[0.75rem] w-[0.875rem]" />
					</div>
				</div>
			</div>
			{isConnected ? (
				<>
					<div className={cnTw('flex w-full gap-2.5 pb-5', !isClaim && 'pb-3')}>
						<Button
							onClick={() => {
								if (isRewardLoading) return
								if (isReward) {
									handleRewardClick()
								}
							}}
							isDisabled={!isReward || isRewardLoading || !isClaim}
							isLoading={isRewardLoading && rewardType === 'reward'}
							className={`${isReward ? 'tail-btn-active' : 'tail-btn-no-data'} !w-2/5 shrink-0`}
						>
							<span>{t('home.withdraw.btn1')}</span>
						</Button>
						<Button
							onClick={() => {
								if (isRewardLoading) return
								if (isReward) {
									handleRewardClick(true)
								}
							}}
							isDisabled={!isReward || isRewardLoading || !isClaim}
							isLoading={isRewardLoading && rewardType === 'stake'}
							className={`${isReward ? 'tail-btn-active' : 'tail-btn-no-data'} !w-full`}
						>
							<span>{t('home.withdraw.btn2')}</span>
						</Button>
					</div>
					{!isClaim && (
						<div className="pb-5 text-sm text-[#CECFE6]">
							<span>
								{t('home.2.withdraw.title')}{' '}
								<span className="ml-1 font-bold text-[#F7931A]">
									{moment.unix(lastReceiveAt).add(AddTime, 'minutes').format('YYYY/MM/DD HH:mm:ss')}
								</span>
							</span>
						</div>
					)}
				</>
			) : (
				<Button onClick={() => open()} className="tail-btn-active mb-5 w-full">
					<span>{t('connect.btn')}</span>
				</Button>
			)}
			<Regular />
		</div>
	)
}
